window.addEventListener('DOMContentLoaded', function () {
	var consumerApplicationForm = document.getElementById('consumerApplicationForm')

	if (!consumerApplicationForm) return

	consumerApplicationForm.addEventListener('submit', function (event) {
		var firstTouchInput = document.createElement('input')
		firstTouchInput.type = 'hidden'
		firstTouchInput.name = 'first_touch'
		firstTouchInput.value = localStorage.getItem('first-touch')

		event.target.appendChild(firstTouchInput)

		return true
	})
})
