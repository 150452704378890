;(function () {
	function isStorageAvailable() {
		try {
			var storage = window.localStorage
			var x = '__storage_test__'

			storage.setItem(x, x)
			storage.removeItem(x)

			return true
		} catch (e) {
			return (
				e instanceof DOMException &&
				(e.code === 22 || // everything except Firefox
					e.code === 1014 || // Firefox
					e.name === 'QuotaExceededError' || // everything except Firefox
					e.name === 'NS_ERROR_DOM_QUOTA_REACHED') && // Firefox
				storage.length !== 0
			)
		}
	}

	if (!isStorageAvailable()) return // TODO: possibly implement cookie based storage in this case

	if (!localStorage.getItem('first-touch')) {
		var data = { referrer_url: document.referrer, url: window.location.toString() }
		localStorage.setItem('first-touch', JSON.stringify(data))
	}
})()
