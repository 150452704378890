/* eslint-disable no-undef */
$(document).ready(function () {
	$('.hamburger-menu').on('click', function () {
		$('.hamburger-content').removeClass('hidden')
		$('nav.topbar').addClass('disabled')
		$('main').addClass('disabled')
	})
	$('.close-menu').on('click', function () {
		$('.hamburger-content').addClass('hidden')
		$('nav.topbar').removeClass('disabled')
		$('main').removeClass('disabled')
	})

	// This line moves the beginning of the home page down whatever height the header is.
	if ($('.hero-unit-container')[0]) {
		$('.hero-unit-container').offset({
			top: $('#newDevBanner').height() + $('.hero-unit-container').offset().top,
		})
	}

	function linkPagination($elements, base, multiplier, startPage) {
		var currentPage = startPage
		var pageCount = $elements.length

		function gotoPage(page) {
			currentPage = page
			var marginLeft = base + multiplier * (page - 1)

			$('.page').removeClass('active')
			$('#desktop-page' + page).addClass('active')
			$('#tablet-page' + page).addClass('active')
			$('#phone-page' + page).addClass('active')
			$('#card-slider .cards').css('margin-left', marginLeft + 'px')
		}

		if ($.isTouchCapable()) {
			$('.card').swipeleft(function () {
				if ($elements.is(':visible')) {
					gotoPage((currentPage % pageCount) + 1)
				}
			})
			$('.card').swiperight(function () {
				if ($elements.is(':visible')) {
					if (currentPage == 1) {
						gotoPage(pageCount)
					} else {
						gotoPage(currentPage - 1)
					}
				}
			})
		} else {
			if ($elements.is(':visible')) {
				$('.slide-nav.left').on('click', function () {
					if (currentPage == 1) {
						gotoPage(pageCount)
					} else {
						gotoPage(currentPage - 1)
					}
				})

				$('.slide-nav.right').on('click', function () {
					gotoPage((currentPage % pageCount) + 1)
				})
			}
		}

		$elements.on('click', function () {
			var page = Number($(this).data('page'))
			gotoPage(page)
		})
	}

	linkPagination($('.card-navigation.desktop .page'), -15, -900, 1)
	linkPagination($('.card-navigation.tablet .page'), 240, -411, 1)
	linkPagination($('.card-navigation.phone .page'), -12, -247, 1)

	$('#clinics-instruction-list .numbered-list-item').on('tap, click, mouseover', function (event) {
		var klass = $(this).data('num')
		$(this).addClass('active')
		$(this).siblings().removeClass('active')

		var $targetPicture = $('#clinics-instruction-list .picture-list .' + klass)
		$targetPicture.siblings().addClass('hidden')
		$targetPicture.removeClass('hidden')
		event.preventDefault()
	})

	$('.print').on('click', function () {
		try {
			window.print()
			// eslint-disable-next-line no-unused-vars
		} catch (error) {
			if (window.dataLayer) window.dataLayer.push({ event: 'print-error' })
		}
	})
})
